import React, { Component } from "react";
import { connect } from "react-redux";
import RNLogoFooter from "@assets/svg/svgui/rn-logo-footer.svg";
import FbIcon from "@assets/svg/svgui/fb-icon.svg";
import TwitterIcon from "@assets/svg/svgui/twitter-icon.svg";
import { META, SUPPORT_EMAIL } from "@constants/config";
import NewsLetterSignupModule from "@components/modules/common/newsletter-signup-module";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportEmailClient: true,
      contactEmailClient: true,
    };
  }

  getFooterStatement = () => {
    return META.description + "<br /><br />Sign up for our weekly newsletter for the each weekend's selections straight to your inbox, with regular ticket giveaways and more.";
  };

  onReportClick = () => {
    setTimeout(() => {
      this.setState(
        Object.assign({}, this.state, {
          reportEmailClient: false,
        })
      );
    }, 200);
  };

  onContactClick = () => {
    setTimeout(() => {
      this.setState(
        Object.assign({}, this.state, {
          contactEmailClient: false,
        })
      );
    }, 200);
  };

  render() {
    return (
      <div
        id="footer"
        className="container container__footer container__fullwidth"
      >
        <div className="container">
          <div className="row">
            <div className="col m6 s12 l6">
              <div className="rn-wordmark-png footer__logo">
                <RNLogoFooter
                  className="rn-wordmark-png-svg footer__logo-svg"
                  style={{ width: 61, height: 61 }}
                />
              </div>
              <div
                className="footer__statement"
                dangerouslySetInnerHTML={{ __html: this.getFooterStatement() }}
              />
            </div>
            <div className="col s12 m4 offset-m2 l3 offset-l3">
              <NewsLetterSignupModule />
            </div>
          </div>
        </div>
        <div className="container container__fullwidth container__footer__social">
          <div className="container">
            <div className="row">
              <div className="col s12">
                <div className="footer__menu">
                  <div className="footer__menu-item report-issue">
                    {this.state.reportEmailClient ? (
                      <a
                        onClick={() => {
                          this.onReportClick();
                        }}
                        href={`mailto:${SUPPORT_EMAIL}?subject=Report%20Issue`}
                      >
                        {"Report an Issue"}
                      </a>
                    ) : (
                      <div className="no-email-client-footer-link">
                        {SUPPORT_EMAIL}
                      </div>
                    )}
                  </div>
                  <div className="footer__menu-item">
                    {this.state.contactEmailClient ? (
                      <a
                        onClick={() => {
                          this.onContactClick();
                        }}
                        href="mailto:info@restlessnites.com?subject=Hello%20Restless%20Nites"
                      >
                        {"Contact"}
                      </a>
                    ) : (
                      <div className="no-email-client-footer-link">
                        info@restlessnites.com
                      </div>
                    )}
                  </div>
                  <div className="footer__menu-item-social">
                    <a
                      href={
                        "https://www.facebook.com/sharer/sharer.php?u=" +
                        encodeURIComponent("https://www.restlessnites.com")
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="fb-icon">
                        <FbIcon
                          className="fb-icon-svg"
                          style={{ width: 20, height: 20 }}
                        />
                      </div>
                    </a>
                    <a
                      href={
                        "https://twitter.com/intent/tweet?url=" +
                        encodeURIComponent("https://www.restlessnites.com")
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="twitter-icon">
                        <TwitterIcon
                          className="twitter-icon-svg"
                          style={{ width: 20, height: 20 }}
                        />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container container__footer__legal">
          <div className="row">
            <div className="col s12">
              <div className="legal-copy">
                {`(c) ${new Date().getFullYear()} Restless Nites. All rights reserved.`}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
