import React, { Component } from "react";
import { Link, navigate } from "gatsby";
import _some from "lodash/some";
import _includes from "lodash/includes";
import _isEmpty from "lodash/isEmpty";
import HamburgerIcon from "@assets/svg/svgui/hamburger-icon.svg";
import RNIcon from "@assets/svg/svgui/rn-logo-clean.svg";
import AccountWidget from "../../containers/global/account-widget";
import OptionSelect from "../../components/form/option-select";
import {
  ROLE_CURATOR,
  ROLE_PROMOTER,
  ROLE_ADMIN,
} from "../../constants/global";
import LoginModal from "../../containers/auth/login_modal";
import RegistrationModal from "../../containers/auth/registration_modal";
import ForgotPasswordModal from "../../containers/auth/forgot_password_modal";
import SearchIcon from "@assets/svg/svgui/search-icon.svg";
import { isWindowDefined } from "../../assets/third-party/blast";
import { TTF_ADMIN_HOST } from "../../constants/config";

class MenuBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      hover: false,
      searchValue: "",
      searchActive: false,
    };

    this.expandTimeout = null;
  }

  componentDidMount = () => {
    this.expandTimeout = setTimeout(() => {
      this.setState(
        Object.assign({}, this.state, {
          expanded: true,
        })
      );
    }, 1000);
  };

  componentWillUnmount = () => {
    clearTimeout(this.expandTimeout);
  };

  getClassNames = () => {
    let classes = "";
    if (this.props.headerBGVisible || this.props.headerVisibilityLocked) {
      classes += " show";
    }

    if (this.props.navOpen) {
      classes += " nav-open";
    }

    if (this.props.headerTheme === "black") {
      classes += " theme-black";
    }

    if (this.state.hover) {
      classes += " hover";
    }

    if (
      this.props.location !== "/events" &&
      this.props.location !== "/curators" &&
      this.props.location !== "/editorial" &&
      this.props.location !== "/login" &&
      this.props.location !== "/submit" &&
      this.props.location !== "/apply-rn" &&
      this.props.location !== "/signup"
    ) {
      classes += " no-active-elements";
    }

    return classes;
  };

  onLocationChange = (e) => {
    sessionStorage.setItem("chosen_region", e.value);

    this.props.updateFilter({
      type: "region",
      value: e.value,
      selected: true,
    });
  };

  onMouseEnter = () => {
    this.setState(
      Object.assign({}, this.state, {
        hover: true,
      })
    );
  };

  onMouseLeave = () => {
    this.setState(
      Object.assign({}, this.state, {
        hover: false,
      })
    );
  };

  getCurrentRegion = () => {
    const { auth } = this.props;

    if (
      _isEmpty(auth.me.data) &&
      isWindowDefined &&
      sessionStorage.getItem("chosen_region")
    ) {
      return sessionStorage.getItem("chosen_region");
    }

    const { currentRegion } = this.props;
    if (currentRegion) return currentRegion.value;
    return "select";
  };

  handleSearchSubmit = (e) => {
    e.preventDefault();
    let searchTerm = this.state.searchValue;
    if (searchTerm.trim().length > 0) {
      this.setState(
        Object.assign(this.state, {
          searchValue: undefined,
          searchActive: false,
        })
      );

      searchTerm = searchTerm.trim().replace(/\s+/g, ",");
      navigate(`/search?query=${searchTerm}`);
    }
  };

  handleSearchChange = (e) => {
    this.setState(
      Object.assign(this.state, {
        searchValue: e.target.value,
      })
    );
  };

  onSearchBlur = () => {
    /*this.setState(Object.assign(this.state, {
      searchActive: false
    }));*/
  };

  toggleSearch = () => {
    this.setState(
      Object.assign(this.state, {
        searchActive: !this.state.searchActive,
      })
    );
  };

  handleCreateAccount = () => {
    const { createModal, removeModal } = this.props;

    const modal = {
      id: "registration_modal",
      Component: () => (
        <div>
          <RegistrationModal
            removeModal={removeModal}
            openLoginModal={this.openLoginModal}
          />
        </div>
      ),
      shouldCloseOnOverlayClick: true,
      isCloseBtn: false,
    };

    this.setState({ expanded: false }, () => {
      createModal(modal, true);
    });
  };

  handleForgotPassword = () => {
    const { createModal } = this.props;

    const modal = {
      id: "forgot_password_modal",
      Component: () => <ForgotPasswordModal />,
      shouldCloseOnOverlayClick: true,
      isCloseBtn: false,
    };

    this.setState({ expanded: false }, () => {
      createModal(modal, true);
    });
  };

  openLoginModal = () => {
    const { createModal, browser, removeModal } = this.props;
    const isMobile = browser.lessThan.medium;

    const modal = {
      id: "login_modal",
      Component: () => (
        <div
          style={{
            maxWidth: isMobile ? "100%" : "480px",
            height: "inherit",
            display: "inline-flex",
            maxHeight: 550,
          }}
        >
          <LoginModal
            validateInput={this.validateInput}
            removeModal={removeModal}
            handleCreateAccount={this.handleCreateAccount}
            handleForgotPassword={this.handleForgotPassword}
          />
        </div>
      ),
      shouldCloseOnOverlayClick: true,
      isCloseBtn: false,
    };
    this.setState({ expanded: false }, () => {
      createModal(modal, true);
    });
  };

  render() {
    const { browser, role } = this.props;

    const allowedRoles = [ROLE_CURATOR, ROLE_PROMOTER, ROLE_ADMIN];
    const isAllowedRole = _some(role, (el) => _includes(allowedRoles, el));
    const redirectDomain = TTF_ADMIN_HOST;

    return (
      <header className={this.getClassNames()}>
        <div className="header-bg" />
        <div className="hamburger-icon" onClick={() => this.props.toggleNav()}>
          <HamburgerIcon
            style={{ width: 29, height: 18 }}
            className="hamburger-icon-svg"
          />
        </div>
        <nav
          className="main-nav hide-on-small-and-down"
          onMouseEnter={() => this.onMouseEnter()}
          onMouseLeave={() => this.onMouseLeave()}
        >
          <ul>
            <li className={this.props.location === "/events" ? "active" : ""}>
              <Link to="/events">Events</Link>
            </li>
            <li
              className={
                this.props.location === "/curators"
                  ? "active"
                  : "" + " hide-on-med-and-down"
              }
            >
              <Link to="/curators">Curators</Link>
            </li>
          </ul>
        </nav>
        <div className={`header-logo ${this.state.expanded ? "expand" : ""}`}>
          <Link to="/">
            <div className="rn-wordmark-svg">
              <RNIcon
                className="rn-wordmark-svg-svg nav-wordmark-svg nav-wordmark"
                style={
                  browser.lessThan.medium
                    ? { width: 33, height: 33 }
                    : { width: 40, height: 40 }
                }
              />
            </div>
          </Link>
          <div className="header-location-selector">
            <span>
              {this.props.regions && (
                <OptionSelect
                  type="regionSelect"
                  editable={Boolean(true)}
                  required={Boolean(false)}
                  name="region"
                  initialValue={this.getCurrentRegion()}
                  options={this.props.regions}
                  defaultOption={{ value: "See Events In:", label: "Select" }}
                  handleChange={this.onLocationChange}
                />
              )}
            </span>
          </div>
        </div>
        <nav
          className="util-nav hide-on-small-and-down"
          onMouseEnter={() => this.onMouseEnter()}
          onMouseLeave={() => this.onMouseLeave()}
        >
          <ul className="clearfix">
            <li
              className={
                this.props.location === "/apply-rn"
                  ? "active"
                  : "" + " hide-on-med-and-down"
              }
            >
              {this.props.loggedIn && <Link to="/orders">MY TICKETS</Link>}
            </li>
            <li
              className={
                this.props.location === "/apply-rn"
                  ? "active"
                  : "" + " hide-on-med-and-down"
              }
            >
              {this.props.loggedIn && isAllowedRole ? (
                <a
                  href={`${redirectDomain}/events/new`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Submit Event
                </a>
              ) : (
                <Link to="/apply-rn">Submit Event</Link>
              )}
            </li>
            {!this.props.loggedIn && (
              <li
                className={`${this.props.location === "/login" ? "active" : ""
                  }`}
              >
                <span aria-hidden={true} onClick={this.openLoginModal}>
                  Login
                </span>
              </li>
            )}
            <li className="util-nav__ticket-option custom-hide hide-on-med-and-down">
              <Link to="/tickets">
                <button className="btn">TICKETS</button>
              </Link>
            </li>
            {this.props.loggedIn && (
              <li>
                <AccountWidget auth={this.props.auth} />
              </li>
            )}
            {false && (
              <li>
                <div className="SVGInline search-icon-svg search-icon">
                  <SearchIcon
                    className="SVGInline-svg search-icon-svg-svg search-icon-svg"
                    style={{ width: 18, height: 18 }}
                  />
                </div>
              </li>
            )}
          </ul>
        </nav>
        <div
          className={`nav-search ${this.state.searchActive ? "nav-search--active" : ""
            }`}
        >
          {this.state.searchActive && (
            <div className="nav-search__input-container">
              <form
                role="search"
                action="."
                onSubmit={(e) => this.handleSearchSubmit(e)}
              >
                <input
                  className="search-input"
                  type="text"
                  placeholder="Search Events"
                  autoFocus={true}
                  value={this.state.searchValue}
                  onBlur={(e) => this.onSearchBlur()}
                  onChange={(e) => this.handleSearchChange(e)}
                />
              </form>
            </div>
          )}
          <button
            onClick={(e) => this.toggleSearch()}
            className="nav-search__search-btn"
          >
            {this.state.searchActive ? (
              <div className="nav-search__close-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
                  <path
                    fill="#fff"
                    d="M8.5 9.5L1 17l-1-1 7.5-7.5L0 1l1-1 7.5 7.5L16 0l1 1-7.5 7.5L17 16l-1 1z"
                  />
                </svg>
              </div>
            ) : (
              <div className="nav-search__search-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
                  <path d="M247 232.8l-68.2-67.4c14.4-17.4 23-39.9 23-64.3C201.8 45.3 156.6 0 100.9 0S0 45.3 0 101.1s45.2 101.1 100.9 101.1c24.2 0 46.3-8.5 63.7-22.6l68.4 67.6c1.9 1.9 4.5 2.9 7 2.9 2.6 0 5.2-1 7.1-3 3.9-4.1 3.9-10.4-.1-14.3zM20 101.1c0-44.7 36.3-81 80.9-81s80.9 36.4 80.9 81-36.3 81-80.9 81-80.9-36.4-80.9-81z" />
                </svg>
              </div>
            )}
          </button>
        </div>
      </header>
    );
  }
}

export default MenuBar;
